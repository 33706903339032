
import { mapMutations } from "vuex";
import auth from "@/mixins/auth.js";

export default {
  layout: 'default',
  mixins: [auth],
  components: {
    Breadcrumb: () => import("@/components/breadcrumb/Breadcrumb.vue"),
    PageTitle: () => import("@/components/elements/PageTitle"),
    BlockTitle: () => import("@/components/elements/BlockTitle"),
    ConfirmPasswordField: () => import('@/components/form/ConfirmPasswordField')
  },
  data() {
    return {
      error: null,
      info: null,
      showPassword: false,
      username: null,
      oldPassword: null,
      password: null,
    }
  },
  head() {
    let customMeta = {
      "metatag":{
        "value":{
          "title":"Mon compte | RCF",
        }
      }
    }
    return this.$headService.buildHead(customMeta)
  },
  mounted() {
    this.$root.$on('DeleteAccount', (event) => this.deleteAccountConfirm(event))
    this.$root.$on('UpdateUsername', (event) => this.updateUsernameConfirm(event))
    this.$root.$on('ConfirmUsername', (event) => this.confirmUsername(event))
    this.$root.$on('UpdatePassword', (event) => this.updatePasswordConfirm(event))
    this.username = this.email = this.$auth.email
  },
  methods: {
    ...mapMutations({
      showModal: "modal/showModal",
      hideModal: 'modal/hideModal',
    }),
    addInfo(message) {
      this.info = message
      this.error = ''
      this.scrollTop()
    },
    addError(message) {
      this.info = ''
      this.error = message
      this.scrollTop()
    },

    /** Username management */
    updateUsername(event) {
      if (this.email.toLowerCase() != this.username.toLowerCase()) {
        this.error = ''
        this.showModal({
          componentName: "AccountManagementModal",
          data: {
            action: 'UpdateUsername',
            oldUsername: this.email,
            newUsername: this.username
          },
          closeOnClickOutside: false
        });
      }
      else {
        this.addError("L'adresse email est identique à celle enregistrée actuellement.")
      }
    },
    async updateUsernameConfirm(event) {
      // Vérification du mot de passe
      await this.$store.dispatch('auth/verifyPassword', event.password)
        .then(async () => {
          // Mise à jour dans Cognito
          await this.$store.dispatch('auth/updateUsername', this.username)
            .then((result) => {
              this.hideModal()

              // Saisi du code de confirmation
              this.showModal({
                componentName: "AccountManagementModal",
                data: {
                  action: 'ConfirmUsername'
                },
                closeOnClickOutside: false
              });
            })
            .catch ((error) => {
              this.hideModal()
              this.addError(this.$cognitoTranslation.getMessage(error))
            })
        })
        .catch((error) => {
          this.hideModal()
          this.addError("Mot de passe incorrect.")
        })
    },
    async confirmUsername(event) {
      await this.$store.dispatch('auth/confirmUpdateUsername', event.code)
        .then(() => {
          // Une fois confirmé, on met à jour dans le CRM
          this.$store.dispatch('contact/updateEmail', { oldEmail: this.email, newEmail: this.username })
            .then(() => {
              this.addInfo("Adresse email mise à jour avec succès !")
            })
            .catch((error) => {
              this.addError("Vous pouvez vous connecter avec cette nouelle adresse, mais une erreur est survenue lors de la mise à jour.")
            })
        })
        .catch((error) => {
          let msg = this.$cognitoTranslation.getMessage(error)
          this.addError(msg)
        })
      this.hideModal()
      
    },
    /** END Username management */

    /** Password management */
    updatePassword(event) {
      this.showModal({
        componentName: "AccountManagementModal",
        data: {
          action: 'UpdatePassword'
        },
        closeOnClickOutside: false
      });
    },
    async updatePasswordConfirm(event) {
      await this.$store.dispatch('auth/updateUserPassword', {oldPassword: event.password, newPassword: this.password})
        .then(() => {
          this.addInfo("Mot de passe mis à jour avec succès !")
        })
        .catch ((e) => {
          this.addError(e.message + '(' + e.code + ')')
        })
      this.hideModal()
    },
    /** END Password management */

    /** Delete account management */
    deleteAccount(event) {
      event.preventDefault()
      this.showModal({
        componentName: "AccountManagementModal",
        data: {
          action: 'DeleteAccount'
        },
        closeOnClickOutside: false
      });
    },
    async deleteAccountConfirm(event) {
      // Disabling buttons
      let submitBtn = document.getElementById("btnAction")
      submitBtn.disabled = true
      // Vérification du mot de passe
      await this.$store.dispatch('auth/verifyPassword', event.password)
        .then(async () => {
          try {
            await this.$store.dispatch('favorites/deleteUserFavorites')
            await this.$store.dispatch('tracker/deleteUserTrackers')
            await this.$store.dispatch('auth/deleteUser')
            await this.$store.dispatch('contact/deleteContact')
            await this.$store.dispatch('auth/logout')
          }
          catch (e) {
            this.addError(e.message + '(' + e.code + ')')
          }
        })
        .catch((error) => {
          this.addError(this.$cognitoTranslation.getMessage(error))
        })

      submitBtn.disabled = false
      this.hideModal()
    },
    /** END Delete account management */

    scrollTop() {
      window.scrollTo(0,0)
    },
  }
}
